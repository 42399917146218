import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import ReactCountdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface HeaderCountdownProps {
  isPaymentComponentVisible: boolean;
}

export const HeaderCountdown: FC<HeaderCountdownProps> = ({
  isPaymentComponentVisible,
}) => {
  const countdownDate = useMemo(() => Date.now() + 900000, []);
  const { isMobile } = useQuery();
  const renderColon = () => <Colon>:</Colon>;
  const products = useSelector(
    (state: AppState) => state.user.selected_plans_options.subscriptions,
  );

  const bookSavings = location.pathname.includes('checkout-special')
    ? '65'
    : '50';

  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <ReactCountdownContainer>
      {/* <CountdownUnitsContainer>
        <Time>{formatted.hours}</Time>
      </CountdownUnitsContainer> */}
      {/* {renderColon()} */}
      <CountdownUnitsContainer>
        <Time>{formatted.minutes}</Time>
      </CountdownUnitsContainer>
      {renderColon()}
      <CountdownUnitsContainer>
        <Time>{formatted.seconds}</Time>
      </CountdownUnitsContainer>
    </ReactCountdownContainer>
  );

  return (
    <HeaderCountDown isPaymentComponentVisible={isPaymentComponentVisible}>
      {isPaymentComponentVisible ? (
        <CountdownTitlePayment>The offer expires in:</CountdownTitlePayment>
      ) : (
        <CountdownTitle>{bookSavings}% discount reserved for:</CountdownTitle>
      )}

      <ReactCountdown date={countdownDate} renderer={renderer} />
    </HeaderCountDown>
  );
};

const CountdownTitlePayment = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem;
`;

const HeaderCountDown = styled.div<{ isPaymentComponentVisible: boolean }>`
  ${({ isPaymentComponentVisible }) =>
    isPaymentComponentVisible &&
    'display: flex;justify-content: space-between;align-items: center;width: 100%;max-width: 44.4rem; margin: 0 auto;'};

  @media ${tablet} {
    ${({ isPaymentComponentVisible }) =>
      isPaymentComponentVisible && 'max-width: 26.75rem;'}
  }
`;

const ReactCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountdownUnitsContainer = styled.div<{ hasMarginLeft?: boolean }>`
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Avenir', 'Open Sans';
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '0.625rem' : '0')};
`;

const Time = styled(Text).attrs({
  color: 'dark100',
})`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 2rem;
`;

const Colon = styled(Time)`
  /* margin: 0 3px; */
  /* font-weight: 900; */
`;

const CountdownTitle = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
